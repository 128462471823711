<template>
  <span v-if="msalInst">
    <a class="btn btn-primary" href @click.prevent="signIn()">
      <i class="fab fa-microsoft"></i> Connexion Azure AD
    </a>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'

export default {
  name: 'AzureSsoButton',
  mixins: [BackendMixin],
  data() {
    return {
      msalInst: null,
    }
  },
  props: {
    backend: Object,
  },
  async created() {
    this.msalInst = this.getAzureClient()
    if (this.msalInst && this.azureAutoConnect()) {
      await this.signIn()
    }
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async signIn() {
      if (this.msalInst) {
        const that = this
        await this.msalInst.acquireTokenPopup(
          {}
        ).then(
          async function(response) {
            const credentials = {
              access_token: response.accessToken,
              id_token: response.idToken,
            }
            const user = await that.loginSSO(that.backend, '/sso-auth/azure-ad-login/', credentials)
            if (user) {
              await router.push({ name: 'home', })
            } else {
              that.addError('L\'authentification a échoué')
            }
          }
        ).catch(
          error => {
            this.addError('Erreur lors de l\'authentification: ' + error)
          }
        )
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
