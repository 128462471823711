<template>
  <div class="login">
    <div class="login-logo">
      <app-logo size="large"></app-logo>
      <demo-warning></demo-warning>
    </div>
    <h1><i class="fa fa-sign-in"></i> Connexion</h1>
    <div v-if="azureSSO">
      <div class="form-fields">
        <b-row>
          <b-col>
            <azure-sso-button
              :backend="selectedBackend"
            ></azure-sso-button>
            &nbsp;
            <a
              class="btn btn-secondary"
              href
              @click.prevent="showEmailLogin = !showEmailLogin"
              v-if="!hideEmailLogin"
            >
              <i class="fas fa-sign-in-alt"></i> Connexion par email
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-form @submit="onSubmit" v-if="show && showEmailLogin">
      <div class="form-fields email-login">
        <b-form-group
          id="email-group"
          label="Adresse email:"
          label-for="email"
          description=""
        >
          <b-form-input
            id="email"
            v-model="form.email"
            type="email"
            required
            :disabled="step > 1"
            placeholder="prenom.nom@exemple.fr"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="backends-group"
          label="Base de données"
          label-for="backends"
          description="Vous êtes utilisateur de plusieurs bases de données. Sélectionnez celle à utiliser"
          v-if="backends.length > 1"
        >
          <b-form-select
            id="backends"
            v-model="selectedBackend"
            @change="onBackendSelected()"
          >
            <b-form-select-option :value="null">Veuillez sélectionner une base de données</b-form-select-option>
            <b-form-select-option :value="backend" v-for="backend in backends" :key="backend.url">
              {{ backend.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-group
          id="password-group"
          label="Mot de passe"
          label-for="password"
          description=""
          v-if="step === 3"
        >
          <b-form-input
            id="password"
            v-model="form.password"
            :type="showPassword ? 'text' : 'password'"
            required
            placeholder=""
          ></b-form-input>
          <div class="text-right">
            <a href @click.prevent="showPassword = !showPassword">
              <i class="fa fa-eye" v-if="showPassword"></i>
              <i class="fa fa-eye-slash" v-else></i>
            </a>
          </div>
        </b-form-group>
      </div>

      <b-row>
        <b-col>
          <a class="btn btn-secondary" href @click.prevent="onReset()">
            Réinitialiser
          </a>
        </b-col>
        <b-col class="text-center">
          <a class="btn btn-secondary" v-if="step === 3" href @click.prevent="onForgotten()">
            Mot de passe oublié?
          </a>
        </b-col>
        <b-col class="text-right">
          <b-button type="submit" variant="primary" :disabled="!isSubmitActive()">
            <span v-if="step===3">Se connecter</span>
            <span v-else>Suivant</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions } from 'vuex'
import DemoWarning from '@/components/Layout/DemoWarning'
import AzureSsoButton from '@/components/Login/AzureSsoButton'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import store from '@/store'
import { isEmailValid } from '@/utils/check'
import { isAuthenticated } from '@/utils/http'
import AppLogo from '@/components/Layout/AppLogo'

export default {
  name: 'login',
  components: {
    AppLogo,
    AzureSsoButton,
    DemoWarning,
  },
  mixins: [BackendMixin],
  data() {
    return {
      frontend: null,
      selectedBackend: null,
      backends: [],
      form: {
        email: '',
        password: '',
      },
      show: false,
      step: 1,
      showEmailLogin: false,
      showPassword: false,
      azureSSO: false,
    }
  },
  mounted() {
    if (isAuthenticated()) {
      router.push({ name: 'home', })
    } else {
      this.onReset()
    }
  },
  watch: {
    frontendInstance: function(newValue, oldValue) {
      this.onReset()
    },
  },
  computed: {
    hideEmailLogin() {
      return this.getFrontendSetting('email_login') === '0'
    },
    frontendInstance() {
      const frontendInstance = store.getters.frontendInstance
      if (frontendInstance) {
        return frontendInstance
      }
      return null
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    onLogin() {
      this.login(this.selectedBackend, this.form.email, this.form.password).then(
        () => {
          router.push({ name: 'home', })
        },
        (err) => {
          this.addError(err.message)
        }
      )
    },

    onBackendSelected() {
      if (this.selectedBackend && this.selectedBackend.url) {
        this.step = 3
      }
    },

    isSubmitActive() {
      if (this.step === 1) {
        return isEmailValid(this.form.email)
      } else if (this.step === 2) {
        return (this.selectedBackend !== null && this.selectedBackend.url)
      } else {
        return this.form.password !== ''
      }
    },

    onSubmit(evt) {
      evt.preventDefault()
      if (this.step === 1) {
        // from the email get the list of emails, we can sign-in.
        if (this.backends.length === 0) {
          this.loadBackendsFromEmail()
        } else {
          if (this.selectedBackend && this.selectedBackend.url) {
            this.step = 3
          } else {
            this.step = 2
          }
        }
      } else if (this.step === 2) {
        if (this.selectedBackend && this.selectedBackend.url) {
          this.step = 3
        }
      } else {
        // The use has chosen the backend -> Login
        this.onLogin()
      }
    },

    loadBackendsFromEmail() {
      if (this.step === 1 && this.form.email) {
        // from the email get the list of backends (instances)
        this.getBackendsFromEmail(this.form.email).then(
          (backends) => {
            if (backends.length === 1) {
              // If only 1 : OK : ask for password
              this.selectedBackend = backends[0]
              this.step = 3
            } else {
              // if several : let the user choose
              // if backends list is empty the API should receive an error
              this.backends = backends
              this.step = 2
            }
          },
          err => {
            this.addError(err.message)
          }
        )
      }
    },

    onForgotten() {
      if (this.selectedBackend && this.selectedBackend.url) {
        const resetUrl = this.selectedBackend.url + '/users/password_reset/'
        window.open(resetUrl, '_blank')
      }
    },

    async onReset() {
      this.show = false
      this.backends = []
      this.selectedBackend = null
      if (this.frontendInstance) {
        const backend = this.frontendInstance.backend
        if (backend && backend.url) {
          this.backends = [backend]
          this.selectedBackend = backend
        }
      }
      this.azureSSO = this.getFrontendSetting('azure_ad_client_id')
      this.showEmailLogin = !this.azureSSO
      this.show = true
      this.form.email = ''
      this.form.password = ''
      this.step = 1
    },
  },
}
</script>

<style lang="less">
  ul.home-menu {
    list-style: none;
    color: #fff;
    margin: 0;
    padding: 0;
  }

  ul.home-menu li {
    padding: 10px 0;
    margin: 12px 0;
    display: block;
    border-bottom: solid 1px #c8cfc8;
  }

  ul.home-menu li a{
    padding: 20px;
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  ul.home-menu li a:hover {
  }

  .login-logo {
    margin-top: 50px;
    margin-bottom: 20px;
    position: relative;
  }

  .login-logo .version-warning-badge {
    position: absolute;
    top: 0;
    display: inline-block;
    vertical-align: bottom;
    margin: 20px;
  }

  .form-fields {
    border: solid 1px #e0e0e0;
    border-radius: 4px;
    padding: 10px;
    margin: 10px 0;
  }
</style>
